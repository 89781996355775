import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from './Tabs';
import GameStats from './GameStats';
import PlayerStats from './PlayerStats';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebaseConfig';

const StatsPage = () => {
  const [highestWeek, setHighestWeek] = useState(0);

  const fetchHighestWeek = () => {
    const gamesRef = ref(database, 'games');
    onValue(gamesRef, (snapshot) => {
      const gamesData = snapshot.val();
      const completedWeeks = Object.values(gamesData)
        .filter(game => game.winner && !game.active)
        .map(game => game.weekNumber);

      const weekCounts = completedWeeks.reduce((acc, week) => {
        acc[week] = (acc[week] || 0) + 1;
        return acc;
      }, {});

      const validWeeks = Object.keys(weekCounts).filter(week => {
        return weekCounts[week] === Object.values(gamesData).filter(game => game.weekNumber === parseInt(week)).length;
      });

      setHighestWeek(Math.max(...validWeeks.map(Number)));
    });
  };

  useEffect(() => {
    fetchHighestWeek();
  }, []);

  return (
    <div className="relative max-w-md mx-auto pt-4 pb-10">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-accent pl-4">Stats Page</h2>
      </div>
      <div className="p-4 bg-gray-800 rounded-lg">
        <Tabs highestWeek={1}>
          <Tab label="Game Stats">
            <GameStats throughWeek={highestWeek} />
          </Tab>
          <Tab label="Player Stats">
            <PlayerStats throughWeek={highestWeek} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default StatsPage;