import React, { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebaseConfig';
import { Tabs, Tab } from './Tabs';
import { FaCheck } from 'react-icons/fa';

const GameStats = ({ throughWeek }) => {
  const [gamesByWeek, setGamesByWeek] = useState({});
  const [userPicks, setUserPicks] = useState({});
  const [teamPickPercentage, setTeamPickPercentage] = useState({});
  const [combinedPickPercentage, setCombinedPickPercentage] = useState({});
  const [highestWeek, setHighestWeek] = useState(throughWeek || 0);

  useEffect(() => {
    setHighestWeek(throughWeek);
  }, [throughWeek]);

  useEffect(() => {
    const fetchData = async () => {
      const gamesRef = ref(database, 'games');
      const picksRef = ref(database, 'picks');

      onValue(gamesRef, (snapshot) => {
        const data = snapshot.val();
        const groupedGames = data ? Object.keys(data).reduce((acc, id) => {
          const game = { id, ...data[id] };
          const week = game.weekNumber;
          if (!acc[week]) acc[week] = [];
          acc[week].push(game);
          return acc;
        }, {}) : {};
        setGamesByWeek(groupedGames);

        // Check for locked games with a week number higher than throughWeek
        const highestLockedWeek = Object.keys(groupedGames).reduce((maxWeek, week) => {
          const weekNumber = parseInt(week, 10);
          const hasLockedGame = groupedGames[week].some(game => game.locked);
          return hasLockedGame && weekNumber > throughWeek ? Math.max(maxWeek, weekNumber) : maxWeek;
        }, throughWeek);

        if (highestLockedWeek > throughWeek) {
          setHighestWeek(highestLockedWeek);
        }
      });

      onValue(picksRef, (snapshot) => {
        const data = snapshot.val();
        setUserPicks(data || {});
      });
    };

    fetchData();
  }, [throughWeek]);

  useEffect(() => {
    if (Object.keys(gamesByWeek).length > 0 && Object.keys(userPicks).length > 0) {
      const calculateTeamPickPercentage = (gamesByWeek, userPicks) => {
        const teamPickPercentage = {};
        const combinedPickPercentage = {};

        Object.keys(gamesByWeek).forEach(week => {
          const games = gamesByWeek[week];
          const weekPickPercentage = {};
          let totalHomePicks = 0;
          let totalAwayPicks = 0;
          let totalGames = 0;

          games.forEach(game => {
            const teamPicks = { home: 0, away: 0 };

            Object.keys(userPicks).forEach(userId => {
              const userPick = userPicks[userId][game.id];
              if (userPick) {
                if (userPick.pick === 'home') {
                  teamPicks.home += 1;
                } else if (userPick.pick === 'away') {
                  teamPicks.away += 1;
                }
              }
            });

            const totalPicks = teamPicks.home + teamPicks.away;
            const homePickPercentage = totalPicks > 0 ? Math.round((teamPicks.home / totalPicks) * 100) : '0';
            const awayPickPercentage = totalPicks > 0 ? Math.round((teamPicks.away / totalPicks) * 100) : '0';

            weekPickPercentage[game.id] = {
              homeTeam: game.homeTeam,
              awayTeam: game.awayTeam,
              homePickPercentage,
              awayPickPercentage,
              locked: game.locked,
              winner: game.winner
            };

            totalHomePicks += teamPicks.home;
            totalAwayPicks += teamPicks.away;
            totalGames += 1;
          });

          teamPickPercentage[week] = weekPickPercentage;
          combinedPickPercentage[week] = {
            homePickPercentage: totalGames > 0 ? Math.round((totalHomePicks / (totalHomePicks + totalAwayPicks)) * 100) : '0',
            awayPickPercentage: totalGames > 0 ? Math.round((totalAwayPicks / (totalHomePicks + totalAwayPicks)) * 100) : '0'
          };
        });

        setCombinedPickPercentage(combinedPickPercentage);
        return teamPickPercentage;
      };

      setTeamPickPercentage(calculateTeamPickPercentage(gamesByWeek, userPicks));
    }
  }, [gamesByWeek, userPicks]);

  return (
    <div className="max-w-4xl mx-auto pt-4">
      <h3 className="text-xl mb-2">Game Stats</h3>
      <Tabs highestWeek={highestWeek}>
        {Object.keys(teamPickPercentage).map(week => (
          <Tab key={week} label={`Week ${week}`}>
            <div className="text-white">
              {Object.keys(teamPickPercentage[week]).map(gameId => (
                <div key={gameId} className="mt-4">
                  {teamPickPercentage[week][gameId].locked && (
                    <div className="mt-2">
                      <div className="flex justify-between mb-1">
                        <span className="flex flex-col items-start">
                          <span>{teamPickPercentage[week][gameId].awayPickPercentage}%</span>
                          <strong className="flex items-center">
                            {teamPickPercentage[week][gameId].awayTeam}
                            {(teamPickPercentage[week][gameId].winner === 'away' || teamPickPercentage[week][gameId].winner === 'push') && (
                              <FaCheck className="text-green-500 ml-1" />
                            )}
                          </strong>
                        </span>
                        <span className="flex flex-col items-end">
                          <span>{teamPickPercentage[week][gameId].homePickPercentage}%</span>
                          <strong className="flex items-center">
                            {(teamPickPercentage[week][gameId].winner === 'home' || teamPickPercentage[week][gameId].winner === 'push') && (
                              <FaCheck className="text-green-500 mr-1" />
                            )}
                            {teamPickPercentage[week][gameId].homeTeam}
                          </strong>
                        </span>
                      </div>
                      <div className="flex items-center">
                        <div className="w-full bg-gray-700 rounded-full h-4 relative">
                          <div
                            className={`h-4 absolute left-0 ${parseFloat(teamPickPercentage[week][gameId].awayPickPercentage) > parseFloat(teamPickPercentage[week][gameId].homePickPercentage) ? 'bg-accent' : 'bg-gray-500'} rounded-l-full`}
                            style={{ width: `${teamPickPercentage[week][gameId].awayPickPercentage}%` }}
                          ></div>
                          <div
                            className={`h-4 absolute right-0 ${parseFloat(teamPickPercentage[week][gameId].homePickPercentage) > parseFloat(teamPickPercentage[week][gameId].awayPickPercentage) ? 'bg-accent' : 'bg-gray-500'} rounded-r-full`}
                            style={{ width: `${teamPickPercentage[week][gameId].homePickPercentage}%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <br className="my-4" />
            <div className="mt-8">
              <div className="flex justify-between mb-1">
                <div className="flex flex-col items-start">
                  <span>{isNaN(combinedPickPercentage[week].awayPickPercentage) ? "0.00" : combinedPickPercentage[week].awayPickPercentage}%</span>
                  <strong>Away</strong>
                </div>
                <div className="flex flex-col items-end">
                  <span>{isNaN(combinedPickPercentage[week].homePickPercentage) ? "0.00" : combinedPickPercentage[week].homePickPercentage}%</span>
                  <strong>Home</strong>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-full bg-gray-700 rounded-full h-4 relative">
                  <div
                    className={`h-4 absolute left-0 ${parseFloat(combinedPickPercentage[week].awayPickPercentage) > parseFloat(combinedPickPercentage[week].homePickPercentage) ? 'bg-accent' : 'bg-gray-500'} rounded-l-full`}
                    style={{ width: `${combinedPickPercentage[week].awayPickPercentage}%` }}
                  ></div>
                  <div
                    className={`h-4 absolute right-0 ${parseFloat(combinedPickPercentage[week].homePickPercentage) > parseFloat(combinedPickPercentage[week].awayPickPercentage) ? 'bg-accent' : 'bg-gray-500'} rounded-r-full`}
                    style={{ width: `${combinedPickPercentage[week].homePickPercentage}%` }}
                  ></div>
                </div>
              </div>
            </div>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default GameStats;