import React, { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebaseConfig';
import Modal from './Modal'; // Import the Modal component

const PlayerStats = ({ throughWeek }) => {
  const [players, setPlayers] = useState([]);
  const [highestWeek, setHighestWeek] = useState(throughWeek || 0);
  const [modalVisible, setModalVisible] = useState(false);
  const [lonerPickDetails, setLonerPickDetails] = useState([]);

  useEffect(() => {
    setHighestWeek(throughWeek);
  }, [throughWeek]);

  useEffect(() => {
    const fetchPlayers = () => {
      const usersRef = ref(database, 'users');
      const picksRef = ref(database, 'picks');
      const gamesRef = ref(database, 'games');

      onValue(ref(database, 'games'), (snapshot) => {
        const gamesData = snapshot.val();
        const completedWeeks = Object.values(gamesData)
          .filter(game => game.winner && !game.active)
          .map(game => game.weekNumber);

        const weekCounts = completedWeeks.reduce((acc, week) => {
          acc[week] = (acc[week] || 0) + 1;
          return acc;
        }, {});

        const validWeeks = Object.keys(weekCounts).filter(week => {
          return weekCounts[week] === Object.values(gamesData).filter(game => game.weekNumber === parseInt(week)).length;
        });

        setHighestWeek(Math.max(...validWeeks.map(Number)));
      });
   
      onValue(usersRef, (snapshot) => {
        const usersData = snapshot.val();
        const playersList = Object.keys(usersData).map((id) => ({
          id,
          name: usersData[id].name,
          cowboysPicks: 0,
          correctCowboysPicks: 0,
          triplePlayWins: 0,
          triplePlayWinsPercentage: 0,
          homePicks: 0,
          awayPicks: 0,
          homePickPercentage: 0,
          awayPickPercentage: 0,
          totalPicks: 0,
          correctPicks: 0,
          pickAccuracy: 0,
          lonerPicks: 0, // New stat
          lonerPickDetails: [], // New state for details
        }));

        onValue(picksRef, (snapshot) => {
          const picksData = snapshot.val();

          onValue(gamesRef, (snapshot) => {
            const gamesData = snapshot.val();

            const calculateCowboysPicks = (player, pick, gameDetails, highestWeek) => {
              const homeTeam = gameDetails?.homeTeam;
              const awayTeam = gameDetails?.awayTeam;

              if (homeTeam === 'Cowboys' || awayTeam === 'Cowboys') {
                player.totalCowboysGames = (player.totalCowboysGames || 0) + 1;

                if ((pick === 'home' && homeTeam === 'Cowboys') || 
                    (pick === 'away' && awayTeam === 'Cowboys')) {
                  player.cowboysPicks += 1;
                }

                if (gameDetails.winner === pick) {
                  player.correctCowboysPicks = (player.correctCowboysPicks || 0) + 1;
                }
              }
              player.cowboysPickPercentage = Math.round(((player.correctCowboysPicks || 0) / (player.totalCowboysGames || 1)) * 100);
            };

            playersList.forEach((player) => {
              const playerPicks = picksData[player.id];
              if (playerPicks) {
                Object.keys(playerPicks).forEach((gameId) => {
                  const pick = playerPicks[gameId].pick;
                  const gameDetails = gamesData[gameId];

                  // Only process picks for games up to the highestWeek
                  if (gameDetails?.weekNumber <= highestWeek) {
                    calculateCowboysPicks(player, pick, gameDetails, highestWeek);

                    if (pick === 'home') {
                      player.homePicks += 1;
                    } else if (pick === 'away') {
                      player.awayPicks += 1;
                    }

                    player.homePickPercentage = Math.round(((player.homePicks || 0) / ((player.homePicks + player.awayPicks) || 1)) * 100);
                    player.awayPickPercentage = Math.round(((player.awayPicks || 0) / ((player.awayPicks + player.homePicks) || 1)) * 100);

                    if (playerPicks[gameId].triplePlay && 
                        (gameDetails.winner === 'push' || (pick === 'home' && gameDetails?.winner === 'home') || 
                          (pick === 'away' && gameDetails?.winner === 'away'))) {
                      player.triplePlayWins += 1;
                      player.triplePlayWinsPercentage = Math.round(((player.triplePlayWins || 0) / (highestWeek || 1)) * 100);
                    }

                    // New stats calculations
                    player.totalPicks += 1;
                    if (gameDetails.winner === pick) {
                      player.correctPicks += 1;
                    }
                    player.pickAccuracy = Math.round((player.correctPicks / player.totalPicks) * 100);

                    // Calculate loner picks
                    const otherPicks = Object.values(picksData).filter(p => p[gameId] && p[gameId].pick === pick);
                    if (otherPicks.length === 1) {
                      player.lonerPicks += 1;
                      player.lonerPickDetails.push({
                        team: pick === 'home' ? gameDetails.homeTeam : gameDetails.awayTeam,
                        week: gameDetails.weekNumber,
                        correct: gameDetails.winner === pick,
                      });
                    }
                  }
                });
              }
            });
            setPlayers(playersList);
          });
        });
      });
    };

    fetchPlayers();
  }, [highestWeek]);

  const handleLonerPicksClick = (details) => {
    setLonerPickDetails(details);
    setModalVisible(true);
  };

  return (
    <div className="max-w-4xl mx-auto pt-4">
      <h3 className="text-xl mb-2">Player Stats</h3>
      <p className="text-sm mb-4">Through week {highestWeek}</p>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-800">
          <thead>
            <tr>
              <th className="text-sm py-2 px-4 break-words sticky left-0 bg-gray-800 ">Player</th>
              <th className="text-sm py-2 px-4 break-words">Cowboys Games</th>
              <th className="text-sm py-2 px-4 break-words">Triple Play Wins</th>
              <th className="text-sm py-2 px-4 break-words">Pick Accuracy</th>
              <th className="text-sm py-2 px-4 break-words">Away/Home Split</th>
              <th className="text-sm py-2 px-4 break-words">Loner Picks</th> {/* New column */}
            </tr>
          </thead>
          <tbody>
            {players.map((player, index) => {
              const rowClass = index % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800';
              return (
                <tr key={player.id} className={rowClass}>
                  <td className={`py-2 px-4 text-center sticky left-0 inset-y-0 ${rowClass}`}>{player.name}</td>
                  <td className="py-2 px-4 text-center">
                    <div className="text-sm">Picked: <span className="text-yellow-500">{player.cowboysPicks}</span></div>
                    <div className="text-sm text-gray-400">Accuracy: <span className={player.cowboysPickPercentage > 50 ? 'text-green-500' : 'text-red-500'}>{player.cowboysPickPercentage}%</span></div>
                  </td>
                  <td className="py-2 px-4 text-center">
                    {player.triplePlayWins}&nbsp;
                    (<span className={player.triplePlayWinsPercentage >= 50 ? 'text-green-500' : 'text-red-500'}>
                      {player.triplePlayWinsPercentage}%
                    </span>)
                  </td>
                  <td className="py-2 px-4 text-center">
                    <span className={player.pickAccuracy < 50 ? 'text-red-500' : 'text-green-500'}>
                      {player.pickAccuracy}%
                    </span>
                  </td>
                  <td className="py-2 px-4 text-center">
                    <div className="text-sm">
                      Away: <span className={player.awayPickPercentage > player.homePickPercentage ? 'text-green-500' : 'text-yellow-500'}>{player.awayPickPercentage}%</span>
                    </div>
                    <div className="text-sm">
                      Home: <span className={player.homePickPercentage > player.awayPickPercentage ? 'text-green-500' : 'text-yellow-500'}>{player.homePickPercentage}%</span>
                    </div>
                  </td>
                  <td className="py-2 px-4 text-center">
                    {player.lonerPicks > 0 ? (
                      <button 
                        onClick={() => handleLonerPicksClick(player.lonerPickDetails)} 
                        className="underline"
                      >
                        {player.lonerPicks}
                      </button>
                    ) : (
                      player.lonerPicks
                    )}
                  </td> {/* New stat */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {modalVisible && (
        <Modal onClose={() => setModalVisible(false)}>
          <h2 className="text-xl mb-4">Loner Pick Details</h2>
          <ul>
            {lonerPickDetails.map((detail, index) => (
              <li key={index} className="mb-2">
                <div>Team: {detail.team}</div>
                <div>Week: {detail.week}</div>
                <div>Correct: {detail.correct ? 'Yes' : 'No'}</div>
              </li>
            ))}
          </ul>
        </Modal>
      )}
    </div>
  );
};

export default PlayerStats;