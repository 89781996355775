import React, { useState, useEffect } from 'react';

const Tabs = ({ children, highestWeek }) => {
  const [activeTab, setActiveTab] = useState(highestWeek - 1);

  useEffect(() => {
    setActiveTab(highestWeek - 1);
  }, [highestWeek]);

  return (
    <div>
      <div className="flex border-b border-gray-700">
        {React.Children.map(children, (child, index) => (
          <button
            key={index}
            className={`px-4 py-2 focus:outline-none ${
              index === activeTab
                ? 'border-b-2 border-accent text-accent'
                : 'text-gray-400 hover:text-white'
            }`}
            onClick={() => {
              setActiveTab(index);
            }}
          >
            {child.props.label}
          </button>
        ))}
      </div>
      <div className="p-4 bg-gray-800 rounded-b">
        {React.Children.map(children, (child, index) => (
          index === activeTab ? child : null
        ))}
      </div>
    </div>
  );
};

const Tab = ({ children }) => <div>{children}</div>;

export { Tabs, Tab };