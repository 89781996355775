import React, { useState, useEffect } from 'react';
import { FaCheck, FaTimes, FaClock, FaStar, FaEye } from 'react-icons/fa';
import { Tabs, Tab } from './Tabs';

const UserDetails = ({ weeks, score, gamesByWeek, userPicks, isAdmin, currentUser }) => {
  const [highestWeek, setHighestWeek] = useState(1);

  useEffect(() => {
    setHighestWeek(Math.max(...Object.keys(gamesByWeek).map(Number)));
  }, [gamesByWeek]);

  const getWeeklyTotal = (userScores, week) => {
    const games = gamesByWeek[week] || [];
    return games.reduce((total, game) => {
      const gameId = game.id;
      return total + (userScores[gameId] || 0);
    }, 0);
  };

  return (
    <tr className="bg-gray-600">
      <td colSpan={weeks.length + 2} className="px-4 py-2">
        <Tabs highestWeek={highestWeek}>
          {weeks.map(week => (
            <Tab key={week} label={`Week ${week}`}>
              <div className="mb-4">
                <h3 className="text-lg font-semibold text-accent">Week {week}</h3>
                <ul className="list-disc list-inside">
                  {gamesByWeek[week].map(game => {
                    const userPick = userPicks[score.userId] && userPicks[score.userId][game.id];
                    const isCorrectPick = game.winner === 'push' || (userPick?.pick === game?.winner);
                    const canViewPick = isAdmin || game.locked || score.userId === currentUser?.uid;
                    return (
                      <li key={game.id} className="flex items-center">
                        {canViewPick ? (
                          <>
                            {game.winner ? (
                              isCorrectPick ? <FaCheck className="ml-2 text-green-500" /> : <FaTimes className="ml-2 text-red-500" />
                            ) : (
                              <FaClock className="ml-2 text-yellow-500" />
                            )} &nbsp; &nbsp;
                            {userPick ? (
                              <>
                                {userPick.pick === 'away' ? <strong>{game.awayTeam}</strong> : game.awayTeam}&nbsp;({game.awaySpread > 0 ? `+${game.awaySpread}` : game.awaySpread})&nbsp;@&nbsp;{userPick.pick === 'home' ? <strong>{game.homeTeam}</strong> : game.homeTeam}&nbsp;({game.homeSpread > 0 ? `+${game.homeSpread}` : game.homeSpread})&nbsp;{userPick.triplePlay ? <FaStar className="text-yellow-500" /> : ""}
                              </>
                            ) : "No Pick"}
                          </>
                        ) : (
                          <>
                            <FaEye className="ml-2 text-yellow-500" /> &nbsp; &nbsp; Pick hidden until game is locked
                          </>
                        )}
                      </li>
                    );
                  })}
                </ul>
                <div className="mt-4">
                  <strong>Weekly Total: {getWeeklyTotal(score.weeklyScores, week)}</strong>
                </div>
              </div>
            </Tab>
          ))}
        </Tabs>
      </td>
    </tr>
  );
};

export default UserDetails;